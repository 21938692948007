<template>
    <div class="app-item app-content">
        <div class="top-title">
            <h4>角色管理</h4>
            <el-button type="primary" size="small" @click="add()">添加角色</el-button>
        </div>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="名称" fixed>
                <template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.name" placeholder="请输入内容 "
                        v-if="showEdit[scope.$index]"></el-input>
                    <span v-if="!showEdit[scope.$index]">{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button v-if="showEdit[scope.$index]" type="text" size="small"
                        @click="updateDepartments(scope.$index, scope.row)">
                        <span>保存</span>
                    </el-button>
                    <el-button v-if="!showEdit[scope.$index]" type="text" size="small"
                        @click="updateDepartments(scope.$index, scope.row)">
                        <span>编辑</span>
                    </el-button>
                    <!-- <el-button type="text" size="small">停用/启用</el-button> -->
                    <el-button type="text" size="small" @click="deleteDepartments(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 添加角色 -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" class="dialog" width="600px">
            <el-form label-width="100px" :rules="rules" ref="ruleForm" :model="ruleFormData">
                <el-form-item label="角色名称" prop="name">
                    <el-input auto-complete="off" placeholder="请输入角色名称" v-model="ruleFormData.name"
                        class="widthBFB"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addDepartments()">确 定</el-button>
                <el-button @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogFormVisible: false,
            ruleFormData: {
                id: "",
                name: '',
            },
            tableData: [],
            showEdit: [], // 显示编辑框
            showBtn: [],
            rules: {
                name: [
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                ],
            },
            title:""
        }
    },
    methods: {
        add() {
            this.title = '添加角色'
            this.dialogFormVisible = true;
            for (let key in this.ruleFormData) {
                this.ruleFormData[key] = ""
            }
        },
        showData(index, obj) {
            console.log(index, obj)
            this.showEdit[index] = !this.showEdit[index]
        },
        saveData(index, obj) {
            console.log(index, obj)
        },
        // 获取角色列表
        getDepartments(url = '') {
            //获取角色列表
            this.$store.dispatch('getRolesList').then(res => {
                if (res) {
                    this.tableData = res;
                }
                // this.$notify({
                //   title: "警告",
                //   message: res.message,
                //   type: "warning"
                // });
            });
        },
        // 增加角色信息
        addDepartments() {
            let res;
            let { id, name } = this.ruleFormData;
            let obj = {
                name: name
            }
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    if (id) {
                        obj.id = id;
                        res = await this.$store.dispatch('updateRoless', obj)
                    } else {
                        res = await this.$store.dispatch('addSaveRoless', obj)
                    }
                    if (res.code === 0) {
                        this.getDepartments()
                        this.$message({
                            message: id ? '修改成功' : '添加成功',
                            type: 'success'
                        });
                        this.dialogFormVisible = false;
                    }
                }
            })
        },
        // 删除角色信息
        deleteDepartments(index, row) {
            let id = row.id
            this.$store.dispatch('deleteRoless', id).then(res => {
                if (res.code === 0) {
                    this.getDepartments();
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                }
            })
        },
        // 修改角色信息
        updateDepartments(index, row) {
            this.title ="编辑角色";
            this.dialogFormVisible = true;
            this.ruleFormData.name = row.name;
            this.ruleFormData.id = row.id;
        }
    },
    mounted() {
        this.getDepartments()
    }
}
</script>
<style lang="less" scoped>
.app-item {
    height: 100%;

    .widthBFB {
        width: 100%
    }

    .top-title {
        padding:0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dialog {
        text-align: center;

        .dialog-footer {
            text-align: center;
            margin-top: -10px;

            .el-button {
                padding: 12px 30px;
            }
        }
    }
}
</style>
  