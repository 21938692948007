<template>
  <div class="newList">
    <div class="content">
      <el-tabs v-model="activeIndex" @tab-click="handleClick" class="tabMenuList">
        <el-tab-pane :label="tag.tag_name" :name="tag.id + ''" v-for="(tag, index) in tags">
          <!--如果有子菜单-->
          <div v-if="tag.children && tag.children.length > 0">
            <el-tag v-for="ll in tag.children" effect="dark" class="mb-20 mr-10 cursor" @click="twoHandleClick(ll.id)">{{ll.tag_name}}</el-tag>
          </div>
          <div class="twoFlexList" >
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-for="(item, index) in news"  :key="index">
                <div class="box clearfix" @click="$router.push({ name: 'Librarydetail', params: { id: item.id } })">
                  <p class="news-free vip" v-html="item.pay_icon"></p>
                  <div class="list">
                    <div class="left">
                      <h4>{{ item.title }}</h4>
                      <span class="info">{{ item.digest }}</span>
                      <span class="time">{{ item.pub_date }}</span>
                    </div>
                    <div class="right">
                      <el-image
                        style="width: 201px; height: 117px"
                        :src="item.cover || '../assets/images/news-img.jpg'"
                        fit="fill">
                      </el-image>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <img src="../../assets/images/noFiled.png" alt="" v-if="news.length == 0" style="max-width: 100%;">
          </div>
        </el-tab-pane>
      </el-tabs>
      <!--page-->
      <div class="pull-center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total">
        </el-pagination>
      </div> 
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "articlesPage",
  components: {},
  data() {
    return {
      total:0,
      tagId: 0,
      tagSubId: 0,
      loading: true,
      activeIndex: "0",
      activeSubIndex: "0",
      tags: {},
      news: [],
      total: 0,
      pageStatu: false,
      baseUrl: "/api/web/articles",
      searchUrl: "",
      showSubTag: false,
      childTag: [],
      page:1,
    }
  },
  methods: {
    handleClick(tab, event) {
      this.childTag = [];
      this.tagSubId = '';
      this.page = 1;
      this.tagId = tab._props.name;
      this.activeIndex = this.tagId + '';
      this.getArticles();
    },
    goTo(page) {
      this.getArticles(page);
    },
    twoHandleClick(tagId) {
      this.tagSubId = tagId;
      this.getArticles();
    },
    // 获取文库列表
    getArticles() {
      const params = {};
      if (this.tagId > 0) {
        params.tag_id = this.tagId;
        if (this.tagSubId > 0) {
          params.tag_children_id = this.tagSubId;
        }
      }
      params.page = this.page;
      let subData = qs.stringify(params);
      this.$store
        .dispatch("getArticles", subData)
        .then(res => {
          this.news = res.data;
          if (res.data.length > 0) {
            this.total = res.meta.total;
            this.pageStatu = true;
          } else {
            this.total = 0;
            this.pageStatu = false;
          }
          this.loading = false;
        })
        .then(() => {
          
        });
    },
    // 获取tag标签
    getProducts() {
      this.$store
        .dispatch("getProduct")
        .then(res => {
          this.tags = [{id:'0', tag_name:'全部'}].concat(res);
          this.activeIndex = this.tags[0].id + '';
          this.tagId = this.tags[0].id;
        })
        .then(() => {
          this.getArticles()
        });
    },
    handleSizeChange(val) {
      console.log(val, 'val');
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getArticles();
    },
  },
  mounted() {
    this.tagId = Number(this.$route.query.tag_id) || 0;
    this.tagSubId = Number(this.$route.query.tag_sub_id) || 0;
    this.getProducts();
    this.getArticles();
  },
  watch: {
    $route(to, from) {
      this.tagId = Number(this.$route.query.tag_id) || 0;
      this.tagSubId = Number(this.$route.query.tag_sub_id) || 0;
      this.showSubTag = false;
      this.activeSubIndex = "0";
      this.getArticles();
      // this.getProducts()
    }
  }
}
</script>
<style lang="less" scoped>

</style>
<style>

</style>
