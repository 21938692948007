<template>
  <div>
    <div class="rightBox">
        <h3>热门资讯</h3>
<!--        <img src="@/assets/images/new_right_img.png" alt="" class="img">-->
        <ul>
          <li><p></p></li>
        <li v-for="(list, index) in rightList" class="flex" @click="$router.push({name:'NewsInfo', query:{id:list.id}})">
            <img src="@/assets/images/new_radio.png" alt="" class="icon">
            {{ list.title }}
        </li>
        </ul>
    </div>
<!--    <div class="rightBox">-->
<!--        <h3>专题导读</h3>-->
<!--        <img src="@/assets/images/new_right_img.png" alt="" class="img">-->
<!--        <ul>-->
<!--        <li v-for="(list, index) in rightList" class="flex" @click="$router.push({name:'NewsInfo', query:{id:list.id}})">-->
<!--          <template v-if="index == 0">-->
<!--            <p>{{ list.title }}</p>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <img src="@/assets/images/new_radio.png" alt="" class="icon">-->
<!--            {{ list.title }}-->
<!--          </template>-->
<!--        </li>-->
<!--        </ul>-->
<!--    </div>-->
<!--    <div class="rightBox">-->
<!--        <h3>专题导读</h3>-->
<!--        <img src="@/assets/images/new_right_img.png" alt="" class="img">-->
<!--        <ul>-->
<!--        <li v-for="(list, index) in rightList" class="flex" @click="$router.push({name:'NewsInfo', query:{id:list.id}})">-->
<!--          <template v-if="index == 0">-->
<!--            <p>{{ list.title }}</p>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <img src="@/assets/images/new_radio.png" alt="" class="icon">-->
<!--            {{ list.title }}-->
<!--          </template>-->
<!--        </li>-->
<!--        </ul>-->
<!--    </div>-->
  </div>
</template>

<script>
  export default {
  name: "rightPage",
  data() {
    return {
      rightList: []
      }
    },
    mounted() {
      this.getNewsHot()
    },
    methods: {
      getNewsHot() {
        this.$store.dispatch("getHotsNews").then((res) => {
          this.rightList = res.data;
        });
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
