<template>
  <div class="app-item app-content">
    <div class="top-title flex">
      <h4>我的文库</h4>
      <div>
        <div class="rightBtn">
          <!-- 部门：
          <DeptVue ref="deptBox" @setData="setParent" :item="depart_id" :multiple="false" class="mr-20" /> -->
          <el-button @click="faBu" type="primary" size="small" v-if="!isMobile">
            发布
            <i class="el-icon-upload el-icon--right"></i>
          </el-button>
        </div>
      </div>
    </div>
    <div class="app-item-nav">
      <!--部门-->
      <el-radio-group v-model="depart_id" @change="setParent(depart_id)" class="mb-10">
        <el-radio-button :label="li.id" v-for="li in deptList" :key="'t_' + li.id">{{ li.name }}
        </el-radio-button>
      </el-radio-group>
      <el-menu default-active="0" mode="horizontal">
        <el-menu-item class="content_nav_item" @click="search('-1')" index="-1">不限</el-menu-item>
        <el-menu-item class="content_nav_item" @click="search('all')" index="0">全部文库</el-menu-item>
        <template v-for="(item, index) in tags">
          <el-menu-item :index="index + 1 + ''" :key="index + '1'" @click="search(item.id, index)">{{ item.tag_name
          }}</el-menu-item>
        </template>
      </el-menu>
      <transition v-if="showSubTag">
        <div class="child_nav" > <!--parentIndex * 15 + 'px' :style="{ marginLeft: '60px' }"-->
          <el-menu :default-active="activeSubIndex" mode="horizontal">
            <template v-for="(item, index) in childTag">
              <el-menu-item class="child_nav_item" :index="item.tag_parent + '-' + index" :key="index"
                @click="searchTag(item.id, index)">{{ item.tag_name }}</el-menu-item>
            </template>
          </el-menu>
        </div>
      </transition>
    </div>
    <el-row :gutter="10">
      <el-col :span="24" v-if="list.length == 0">
        <div class="noList">
          <p><img src="../../assets/images/noFiled.png" alt="" style="max-width: 100%;"></p>
          暂无记录
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="11" :lg="11" :xl="11" class="content-item" v-for="(item, index) of list"
        :key="index">
        <div class="grid-content bg-purple clearfix">
          <el-col :xs="7" :sm="4" :md="6" :lg="6" :xl="6">
            <img :src="item.cover" alt />
          </el-col>
          <el-col :xs="13" :sm="16" :md="12" :lg="12" :xl="12" class="app-content-item">
            <p>{{ item.title }}</p>
            <span>{{ item.digest }}</span>
          </el-col>
          <el-col :xs="4" :sm="4" :md="6" :lg="6" :xl="6" class="app-content-date">
            <p>{{ item.pub_date.slice(8) }}</p>
            <span>{{ item.pub_date.slice(0, 7) }}</span>
          </el-col>
          <div class="item-choice">
            <ul>
              <li @click="getIsShow(item)" v-if="item.can_modify">{{item.is_show == 1 ? '下架' : '上架'}}</li>
              <li @click="seeArticle(item.id)">查看</li>
              <li @click="getArticle(item)" v-if="item.can_modify && !isMobile" >修改</li>
              <li @click="deleteArticle(item.id)" v-if="item.can_modify">
                删除
              </li>
              <li @click="getRecords(item)" v-if="btnShow" class="item-record">
                阅读记录
              </li>
            </ul>
          </div>
        </div>
        <el-badge :value="item.status" class="item"> </el-badge>
        <!-- <i >{{item.status}}</i> -->
      </el-col>
    </el-row>
    <Page :total="total" @goTo="goTo" v-if="isShow && list.length > 0" :pageSize="pageSize" ></Page>
  </div>
</template>
<script>
import Page from "../../components/Page.vue";
// import DeptVue from "./components/Dept";
export default {
  components: {
    Page,
    // DeptVue
  },
  data() {
    return {
      tags: [],
      list: [],
      total: 0,
      page: 1, // 当前页面
      lastPage: 1, // 最后一页
      pageSize: 10, // 每页显示条数
      store: {},
      isShow: true,
      btnShow: true,
      loading: true,
      showSubTag: false,
      childTag: [],
      activeSubIndex: "0",
      tagId: 0,
      tagSubId: 0,
      parentIndex: 0,
      depart_id: "",
      deptList: []
    };
  },
  methods: {
    getDepList() {
      this.$store.dispatch("getDepartments").then(res => {
        if (res) {
          this.deptList = [{id:"-1",name:"不限"},{ id: "", name: '总部' }].concat(res)
        }
      })
    },
    setParent(data) {
      let url = `/api/web/store/articles?tag_id=${this.tagId}&tag_sub_id=${this.tagSubId}&depart_id=${data}`
      this.getStoreArticles(url);
    },
    // 获取tag标签
    getTags() {
      this.$store.dispatch("getProduct").then((res) => {
        this.tags = res;
      });
    },
    activeTag() {
      this.tags.map((item) => {
        if (this.tagId === item.id) {
          //this.activeIndex = String(key + 1);
          this.childTag = item.children;
          // this.childTag.map((subItem, subKey) => {
          //   if (subItem.id === this.tagSubId) {
          //     this.activeSubIndex = item.id + "-" + subKey;
          //   }
          // });
          let clientWidth =
            document.documentElement.clientWidth || document.body.clientWidth;
          this.showSubTag = false;
          if (clientWidth >= 768) {
            this.showSubTag = true;
          }
        }
      });
    },
    searchTag(id, index) {
      this.tagSubId = id;

      this.tags.map((item, key) => {
        if (this.tagId === item.id) {
          this.activeIndex = String(key + 1);
          this.childTag.map((subItem, subKey) => {
            if (subItem.id === this.tagSubId) {
              this.activeSubIndex = item.id + "-" + subKey;
            }
          });
        }
      });
      let url_ = `/api/web/store/articles?tag_id=${this.tagId}&tag_sub_id=${this.tagSubId}&depart_id=${this.depart_id}`
      this.getStoreArticles(url_);
    },
    search(id, index) {
      this.tagId = id;
      this.activeSubIndex = "0";
      this.parentIndex = index - 2;
      if (['-1', 'all'].includes(id)) {
        this.childTag = []
      }
      this.getPageData(id)
    },
    // 获取我的主页信息
    getStoreArticles(url) {
      this.$store.dispatch("getStoreArticles", url).then((res) => {
        // console.log(res.data.length)
        if (url) {
          this.list = [];
        }
        this.list = res.data;
        // if (this.list.status) {
        this.list.forEach((ele, ind) => {
          if (ele.status === 1) {
            ele.status = "成功";
          } else if (ele.status === 0) {
            ele.status = "审核中";
          } else {
            ele.status = "失败";
          }
        });
        // }
        let total = res.total;
        this.total = total;
        this.isShow = total != 0;
        this.pageSize = res.per_page
        this.loading = false;
      });
    },
    // 翻页
    goTo(page) {
      this.getPageData(this.tagId, page)
    },
    getPageData(id, page = "") {
      if (id === "all" || id == 0 || this.tagId == 'all') {
        this.getStoreArticles(`/api/web/store/articles?page=${page}&depart_id=${this.depart_id}`);
      } else {
        this.getStoreArticles(`/api/web/store/articles?tag_id=${this.tagId}&page=${page}&depart_id=${this.depart_id}`);
        this.activeTag();
      }
    },
    // 获取单个文章 （修改）
    getArticle(item) {
      this.loading = true;
      this.$store.dispatch("storeCanPub", item.id).then((res) => {
        if (res.code * 1 == 0) {
          this.$router.push({ name: "Publish", query: { id: item.id } });
        } else {
          this.$message.error("没有权限");
        }
        this.loading = false;
      });
    },
    //上下架
    getIsShow(item) {
      let url = item.is_show == 1 ? 'stop_show' : 'start_show';
      this.loading = true;
      this.$store.dispatch('pushIsShow', {url, id:item.id}).then((res) => {
        if (res.code * 1 == 0) {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getStoreArticles();
        } else {
          this.$message.error("没有权限");
        }
        this.loading = false;
      });
    },
    // 查看文章
    seeArticle(id) {
      this.$router.push({ name: "StoreDetail", params: { id } });
    },
    faBu() {
      this.loading = true;
      this.$store.dispatch("storeCanPub", "").then((res) => {
        if (res.code * 1 == 0) {
          this.$router.push({ name: "Publish" });
        } else {
          this.$message.error("没有权限");
        }
        this.loading = false;
      });
    },
    // 删除文章
    deleteArticle(id) {
      this.loading = true;
      this.$store.dispatch("storeCanPub", id).then((res) => {
        if (res.code * 1 == 0) {
          this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch("deleteArticles", id).then((res1) => {
              if (res1.code === 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getStoreArticles();
              }
            });
          }).catch(() => { });
        } else {
          this.$message.error("没有权限");
          this.loading = false;
        }
      });
    },
    // 阅读记录
    getRecords(item) {
      this.$router.push({ name: "Viewrecord", query: { id: item.id } });
    },
  },
  mounted() {
    this.getDepList();
    this.getTags();
    this.getStoreArticles();
    // 判断是不是商家
    this.$store.dispatch("getInfo").then((res) => {
      // console.log(res)
      this.store = res.info.store || res.info.stores;
      if (!(this.store && this.store.status > 0)) {
        this.btnShow = !this.btnShow;
      }
      this.loading = false;
    });
  },
  computed: {
    isMobile() {
      let bool = localStorage.getItem('isMobile')
      return bool == "true"
    }
  }
};
</script>
<style lang="less" scoped>
.app-item {
  .top-title {
    padding:0 10px;
    justify-content: space-between;
  }

  // 导航
  .app-item-nav {
    padding:0 10px;
    margin-bottom: 20px;

    .el-menu {
      border-bottom: none;

      .el-menu-item {
        border: 1px solid #eee;
        border-bottom-color: #eee !important;
        padding: 0 0.4rem;
        line-height: 1rem;
        white-space: normal;
        height:40px !important;
        min-width:80px;
        display: -webkit-box !important;
        .flex;
        & + .el-menu-item{
          border-left:0
        }
        &:hover{
          background:#f5f5f5;
        }
      }

      .is-active {
        background-color: @global-head-active-color !important;
        border-bottom: none;
        color: @global-white !important;
        border: 1px solid @global-head-active-color !important;
      }
    }

    .child_nav {
      margin: 0;

      .el-menu-item {
        height: 40px !important;
        border:0;
        border-bottom: 2px solid #ddd;
      }

      .is-active {

        color: #efce2b !important;
        background: none !important;
        border: 0 !important;
        border-bottom: 4px solid #efce2b !important
      }
    }

  }

  .grid-content {
    display: flex;
    align-items: center;
    position: relative;
  }

  .item-choice {
    background: url("../../assets/images/dark_bg.png");
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    transform: scale(0, 1);
    transition: opacity 0.35s, transform 0.35s;

    ul {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-left: 0;
      margin: 0;

      li {
        width: 100%;
        flex: 1;
        .flex;
        cursor: pointer;
        // font-size: 16px
      }

      .item-record {
        font-size: 14px;
      }
    }
  }

  .grid-content:hover .item-choice {
    opacity: 0.61;
    transform: scale(1);
  }

  .content-item {
    background: #f5f5f5;
    margin: 10px 10px;
    padding: 10px 0;
    position: relative;

    // overflow: hidden;
    .el-badge {
      position: absolute;
      top: -10px;
      right: -12px;
      font-size: 14px;
    }

    img {
      width: 80px;
      height: 100px;
    }

    .app-content-item {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
        color: #3b3b3b;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 1px;
      }

      span {
        font-size: 14px;
        color: #c0c0c1;
        line-height: 23px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
    }

    .app-content-date {
      border-left: 2px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0;

      p {
        color: #3f3f3c;
        font-size: 28px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}

.rightBtn {
  display: flex;
  justify-content: right;
  line-height: 36px;

  .mr-20 {
    margin-right: 20px
  }
}

@media screen and (max-width: 700px) {
  .app-item {
    padding: 0;

    .content-item {
      margin: 10px 0;
    }

    .el-row {
      padding: 0 1rem;
    }

    .app-item-nav {
      .el-menu--horizontal {
        .flex;
        display: block;
        justify-content: space-between;
      }

      .el-menu-item {
        padding: 0 3px !important;
        width: calc(50% - 10px) !important;
        height: 3rem;
        line-height: 1.25rem;
        margin:0 5px 5px 0;
        & + .el-menu-item{
          border-left:0;
        }
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .app-item {
    .content-item {
      img {
        width: 50px;
        height: 70px;
      }
    }

    // .app-item-nav{
    //   .el-menu-item{
    //     padding:0 1px !important
    //   }
    // }
  }
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-10{
  margin-bottom: 10px;
}

.noList{
  width:50%;
  margin:0 auto;
  text-align: center;
  p{
    padding-top:45px;
  }
  img{
    max-width: 100%;
    display: block;
  }
}
</style>
